import { getRequest, postRequest } from './index'
// 组织结构相关的几个接口
// 获取组织机构的列表
export const getOrganizationList = (successCallback, failureCallback) => {
  getRequest(
    '/organization/list',
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 删除组织机构
export const deleteOrganization = (organizationId, successCallback, failureCallback) => {
  getRequest(
    '/organization/delete?organizationId=' + organizationId,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 添加或者编辑机构
export const addAndUpdateOrganization = (data, successCallback, failureCallback) => {
  postRequest('/organization/addAndUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 角色相关的接口
// 获取所有角色   不分企业
export const getAllRoles = (organizationId, successCallback, failureCallback) => {
  getRequest(
    `/role/allRoles?cmpRole=${organizationId || '03'}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 添加角色
export const addRole = (data, successCallback, failureCallback) => {
  postRequest('/role/addRole', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 编辑角色
export const updateRole = (data, successCallback, failureCallback) => {
  postRequest('/role/updateRole', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除角色
export const deleteRole = (roleCode, successCallback, failureCallback) => {
  getRequest('/role/delete?roleCode=' + roleCode, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据角色获取它的权限roleCode
export const getRoleOfResource = (roleCode, successCallback, failureCallback) => {
  postRequest('/role/roleOfResource?roleCode=' + roleCode, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 用户的相关接口
// 获取所有用户的接口带分页
export const getUserByPage = (data, successCallback, failureCallback) => {
  postRequest('/user/listbypage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加或者编辑用户信息
export const addAndUpdataUser = (data, successCallback, failureCallback) => {
  postRequest(data.passwordValue ? '/user/add' : '/user/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 重置密码
export const userResetPassword = (id, successCallback, failureCallback) => {
  getRequest('/user/reset?userId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户的启用与停用
export const userEnabledAndStop = (data, successCallback, failureCallback) => {
  postRequest('/user/userST', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询所有的权限
export const getAllPermission = (successCallback, failureCallback, roleType) => {
  postRequest(`/role/getResourceSelect?roleType=${roleType || '03'}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 保存角色权限
export const saveRolePermission = (data, successCallback, failureCallback) => {
  postRequest('/role/addAndUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 权限列表
// 权限列表(带分页)菜单
export const getResourceByPage = (data, successCallback, failureCallback) => {
  postRequest('/role/resourceByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增权限菜单
export const addAndEditPermission = (data, successCallback, failureCallback) => {
  postRequest(data.type === 'edit' ? '/role/resourceUpdate' : '/role/resourceAdd', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除权限菜单
export const deletePermission = (resCode, successCallback, failureCallback) => {
  postRequest('/role/resourceDelete', { resCode }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开通系统及角色级联
export const baseinfoSystemAndRole = (successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/systemAndRole', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 设为超级管理员
export const baseinfoChangAdmin = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/changAdmin', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取企业开通系统
export const baseinfoCmpRole = (successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/cmpRole', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 平台资源列表
export const getResourceBySysSelect = (roleType, successCallback, failureCallback) => {
  getRequest('/role/getResourceBySysSelect?roleType=' + roleType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

<template>
  <!-- 组织管理 -->
  <div class="page-info-content">
    <div class="organizationalManagement">
      <div class="page-tree">
        <el-tree
          :data="datas"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        >
          <span slot-scope="{ node, data }" class="custom-tree-node">
            <span>{{ data.orgName }}</span>
            <span>
              <el-button type="text" size="mini" @click="() => append(data)">新增</el-button>
              <el-button
                v-if="data.orgPcode"
                type="text"
                size="mini"
                @click="() => remove(node, data)"
              >删除</el-button>
            </span>
          </span>
        </el-tree>
      </div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="page-form"
      >
        <el-form-item label="组织名称" prop="orgName">
          <el-input v-model="ruleForm.orgName" />
        </el-form-item>
        <el-form-item label="组织编码" prop="orgCode">
          <el-input v-model="ruleForm.orgCode" disabled />
        </el-form-item>
        <el-form-item label="组织说明" prop="remark">
          <el-input v-model="ruleForm.remark" maxlength="200" show-word-limit type="textarea" />
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" class="widen-button" type="primary" @click="saveEdit">
            确定
          </el-button>
          <el-button class="widen-button" @click="ruleForm = {}">
            重置
          </el-button>
        </el-form-item>
      </el-form>
      <el-dialog
        title="新增组织"
        :visible.sync="editFormVisible"
        width="32%"
        :close-on-click-modal="false"
        @click="closeDialog('edit')"
      >
        <el-form ref="editForm" label-position="left" label-width="120px" :model="editForm" :rules="rules">
          <el-form-item label="当前节点名称">
            <el-input
              v-model="editForm.orgPname"
              size="small"
              disabled
              auto-complete="off"
              placeholder="请输入当前节点名称"
            />
          </el-form-item>
          <el-form-item label="当前节点编码">
            <el-input
              v-model="editForm.orgPcode"
              size="small"
              disabled
              auto-complete="off"
              placeholder="请输入当前节点编码"
            />
          </el-form-item>
          <el-form-item label="组织名称" prop="orgName">
            <el-input
              v-model="editForm.orgName"
              size="small"
              auto-complete="off"
              placeholder="请输入组织名称"
            />
          </el-form-item>
          <el-form-item label="组织说明">
            <el-input
              v-model="editForm.remark"
              type="textarea"
              size="small"
              autofocusmaxlength="200"
              show-word-limit
              auto-complete="off"
              placeholder="请输入组织说明"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" plain class="widen-button" @click="editFormVisible = false">
            取消
          </el-button>
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            class="title widen-button"
            @click="submitForm('editForm')"
          >
            保存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 转为树结构
import { translateDataToTree } from '@/unit/index'
import { getOrganizationList, addAndUpdateOrganization, deleteOrganization } from '@/http/custApi/institutionalFramework'
export default {
  data() {
    return {
      editFormVisible: false,
      loading: false,
      editForm: {},
      ruleForm: {},
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        orgName: [
          { required: true, message: '请输入组织名称', trigger: 'blur' },
          { min: 1, max: 65, message: '长度在 1 到 65 个字符', trigger: 'blur' }
        ]
      },
      datas: []
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      getOrganizationList(res => {
        this.datas = translateDataToTree(res.data, 'orgPcode', 'orgCode')
      })
    },
    // 新增子组织
    append(data) {
      this.editFormVisible = true
      this.editForm = {}
      this.editForm.orgPcode = data.orgCode
      this.editForm.orgPname = data.orgName
    },
    // 点击当前tree的节点
    handleNodeClick(node) {
      this.ruleForm = { ...node }
    },
    // 点击新增保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          addAndUpdateOrganization({ params: this.editForm }, res => {
            if (res.code === 200) {
              this.initList()
              this.$message.success('添加成功！')
            }
            this.editFormVisible = false
            this.loading = false
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 点击修改保存
    saveEdit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          delete this.ruleForm.createTime
          delete this.ruleForm.modifyTime
          addAndUpdateOrganization({ params: this.ruleForm }, res => {
            if (res.code === 200) {
              this.initList()
              this.$message.success('成功！')
            }
            this.loading = false
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 删除节点
    remove(node, data) {
      this.$confirm('此操作将删除此条组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteOrganization(data.orgCode, res => {
            if (res.code === 200) {
              this.initList()
              this.$message.success('删除成功！')
              return
            }
            this.$message.error(res.msg)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style scoped>
.organizationalManagement {
  padding: 30px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 100%;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-form,
  .el-tree {
    flex: 0 0 70%;
  }
</style>
